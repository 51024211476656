import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import styled from 'styled-components';
import './style.scss';

const VersionChip = styled.span`
  color: ${props => props.textColor || '#000000'};
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  margin: 0 0.7rem;
`

const constructChipTexts = tag => {
  let texts = [];
  /* NOT display chip (rc, beta, build) if the tag is older than latest*/
  if (tag.latest)
    texts.push({
      text: "Latest",
      txColor: "#0fa700",
      bgColor: "green"
    });
  if (tag.afterLatest) {
    if (tag.beta > 0)
    texts.push({
      text: "beta-"+tag.beta,
      txColor: "blue",
      bgColor: "blue"
    });
  if (tag.build > 0)
    texts.push({
      text: "build-"+tag.build,
      txColor: "blue",
      bgColor: "blue"
    })
  if (tag.rc > 0)
    texts.push({
      text: "RC"+tag.rc,
      txColor: "#a70000",
      bgColor: "red"
    })
  }
  if (tag.deprecated)
    texts.push({
      text: "Deprecated",
      txColor: "#7f7f7f",
      bgColor: "grey"
    })
  return texts;
}

const MobileFacetRadioItem = (props) => {
  const { onChangeHandler, checkedExpr, value, id, label, chips = [] } = props;

  return <div className="radio-item">
    <div className="Input__Radio">
      <input type="radio" id={`${id}_mobile`} value={value}
        onChange={onChangeHandler}
        checked={checkedExpr} />
      <label // eslint-disable-line jsx-a11y/label-has-associated-control
        htmlFor={`${id}_mobile`}></label>
    </div>
    <label className="check-label" htmlFor={`${id}_mobile`}>
      {label}
      {
        chips.length > 0 &&
          chips.map(chip => <VersionChip
              key={`${label}_${chip.text}_mobile`}
              textColor={chip.txColor}
              chipColor={chip.bgColor}
            >
              {chip.text}
            </VersionChip>)
      }
    </label>
  </div>
}

const MobileFacetFilterBox = (props) => {
  let { configUpdateMethod, searchConfig, isCollection, lockFile = {
      entry_points: [],
    }, 
    filterUpdateMethod, 
    filterState = {
      versionList: [],
      selectedRepo: '*',
      selectedVersion: 'latest',
      versionLabel: '',
      sectionLabel: 'Entire site'
    } } = props;
  let {
    versionList: mobileVersionList,
    selectedRepo: mobileSelectedRepo,
    selectedVersion: mobileSelectedVersion,
    versionLabel: mobileVersionLabel,
    sectionLabel: mobileSectionLabel
  } = filterState;
  const repoList = isCollection ? Object.keys(lockFile.entry_points).filter((item, idx) => idx !== 0) 
    : Object.keys(lockFile.entry_points);
  const [hideFilters, toggleHideFilters] = useState(true);

  const mobileSelectRepo = (pathName, label) => {
    let tags = pathName !== "*" ? lockFile.entry_points[pathName].tags : [];
    let searchVersion = "latest";
    let versionLabel = "";

    if (tags.length > 0) {
      versionLabel = tags.filter(tag => tag.latest)[0].tag_reduced_friendly;
    }

    filterUpdateMethod({
      versionList: tags,
      selectedRepo: pathName,
      selectedVersion: searchVersion,
      versionLabel: versionLabel,
      sectionLabel: label
    })

    if (pathName === '*') {
      configUpdateMethod({
        ...searchConfig,
        page: 1,
        facetFilters: ['*']
      })
    } else if (pathName === '/' || pathName === '') {
      configUpdateMethod({
        ...searchConfig,
        page: 1,
        facetFilters: ["tags:root"]
      })
    } else {
      configUpdateMethod({
        ...searchConfig,
        page: 1,
        facetFilters: [`path:${pathName}`, `version:${searchVersion}`]
      })
    }
  }

  const mobileSelectVersion = (searchVersion, label) => {
    filterUpdateMethod({
      ...filterState,
      selectedVersion: searchVersion,
      versionLabel: label
    })

    configUpdateMethod({
      ...searchConfig,
      page: 1,
      facetFilters: [`path:${mobileSelectedRepo}`, `version:${searchVersion}`]
    })
  }

  return (
    <div className="FilterBox__MobileWrapper">
      <div className="FilterBox__Label">
        <span id="FilterLabel">Filter</span>
        <span id="FacetDisplay">Section: {`${mobileSectionLabel} ${mobileSectionLabel === 'Entire site' ? '' : ' | '+mobileVersionLabel}`}</span>
        {
          hideFilters 
            ? <FaChevronDown className="caret-d" onClick={() => toggleHideFilters(false)}/>
            : <FaChevronUp className="caret-d" onClick={() => toggleHideFilters(true)}/>
        }
      </div>
      <div className={`FilterBox__Facets ${hideFilters ? 'Hidden' : ''}`}>
        <span className="box-label">Section</span>
        <MobileFacetRadioItem id="*" value="*" label="Entire site" 
          onChangeHandler={() => mobileSelectRepo("*", "Entire site")}
          checkedExpr={mobileSelectedRepo === "*"} />
        {
          repoList.length > 0 &&
            repoList.map((entryPath, index) => {
              let label = (lockFile.entry_points[entryPath].repo === 'eos') ? 'eosio' : lockFile.entry_points[entryPath].repo;

              return <MobileFacetRadioItem key={index} id={entryPath} value={entryPath} label={label}
                onChangeHandler={() => mobileSelectRepo(entryPath, label)}
                checkedExpr={mobileSelectedRepo === entryPath} />
            })
        }
      </div>
      {
        mobileVersionList.length > 0 &&
        <div className={`FilterBox__Facets FilterBox__Version ${hideFilters ? 'Hidden' : ''}`}>
          <span className="box-label">Version</span>
          {
            mobileVersionList.map((tagItem, index) => {
              let chipTexts = constructChipTexts(tagItem);
              if (tagItem.latest) {
                return <MobileFacetRadioItem key={index} id={"latest"} value={"latest"} label={tagItem.tag_reduced_friendly}
                  onChangeHandler={() => mobileSelectVersion("latest", tagItem.tag_reduced_friendly)}
                  checkedExpr={mobileSelectedVersion === "latest"} 
                  chips={chipTexts} />
              }
              return <MobileFacetRadioItem key={index} id={tagItem.tag_reduced_friendly} value={tagItem.tag_reduced_friendly}
                label={tagItem.tag_reduced_friendly}
                onChangeHandler={() => mobileSelectVersion(tagItem.tag_reduced_friendly, tagItem.tag_reduced_friendly)}
                checkedExpr={mobileSelectedVersion === tagItem.tag_reduced_friendly} 
                chips={chipTexts} />
            })
          }
        </div>
      }
    </div>
  )
}

export default MobileFacetFilterBox;
